/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                var $body = $('body'),
                    $header = $('header'),
                    $navMobile = $('.nav-mobile'),
                    $toggleMenu = $('.toggle-menu');

                /* open/close mobile menu */
                $toggleMenu.on('click', function(e) {
                    e.preventDefault();

                    $body.toggleClass('no-scroll');
                    $header.toggleClass('nav-opened');
                    $navMobile.toggleClass('opened');
                });

                /* close mobile menu when clicking on a link */
                $navMobile.on('click', 'a', function(e) {
                    $navMobile.find('.toggle-menu').click();
                });

                /* lazyloaded images - add .lazyloaded to image wrapper */
                $('img.lazyload').each(function(){
                    this.addEventListener('load', function(e) {
                        $(e.target).parent('.img-wrapper').addClass('lazyloaded');
                    }, true);
                });

                /* header animation */
                var header_top = 0,
                    current_scroll = false,
                    current_scroll_direction = 'up';

                if ($('html').hasClass('touchevents')) {
                    document.addEventListener('scroll', headerOnScroll, false);
                }

                function headerOnScroll() {
                    var scroll = $(this).scrollTop(),
                        scroll_difference = Math.abs(scroll - current_scroll);

                    // on page top
                    if (current_scroll == 0 || current_scroll === false || scroll <= 80) {
                        current_scroll !== false && minifyAnimations();
                    }
                    // on scroll up
                    else if (scroll < current_scroll) {
                        if (current_scroll_direction === 'down') {
                            current_scroll_direction = 'up';
                            expandAnimations();
                        }
                    }
                    // on scroll down
                    else {
                        if (current_scroll_direction === 'up') {
                            current_scroll_direction = 'down';
                            minifyAnimations();
                        }
                    }

                    header_top = $header.offset().top;
                    current_scroll = scroll;
                };

                function expandAnimations() {
                    $header.addClass('header-fixed');
                }

                function minifyAnimations() {
                    $header.removeClass('header-fixed');
                }
            },

            finalize: function() {
                // JavaScript to be fired after the init JS

                $('.img-wrapper:not(.lazyloaded) img.lazyloading, .img-wrapper:not(.lazyloaded) img.lazyloaded').each(function() {
                    $(this).parent('.img-wrapper').addClass('lazyloaded');
                });

                // email address
                var obfuscated = {
                    at: ['@'],
                    cia: ['v', 'a', 'n', 'c', 'i', 't', 'y'],
                    inbox: ['v', 'c', 'i', 'm'],
                    ext: ['.', 'c', 'o', 'm']
                },
                result = obfuscated.inbox.join('') + obfuscated.at.join('') + obfuscated.cia.join('') + obfuscated.ext.join('');
                $('.inbox').text(result).attr('href', 'mailto:' + result);
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },

            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Archive page
        'archive': {
            init: function() {

                $('.team-member').on('click', '[data-team-member]', function(e) {
                    e.preventDefault();

                    var $this        = $(this),
                        $target      = $('#teamMemberModal'),
                        $target_body = $target.find('.modal-body');

                    $target_body.empty();

                    $.ajax({
                        url: ajax_data.url,
                        type: 'GET',
                        dataType: 'html',
                        data: {
                            action: 'load_team_member',
                            team_member_id: $(this).data('team-member-id')
                        }
                    }).success(function(data, textStatus, jqXHR) {
                        // modal: animation on open
                        $target.one('show.bs.modal', function(e) {
                            $(this).find('.modal-dialog').attr('class', 'modal-dialog animated slideInDown');
                        });

                        // $('#teamMemberModal').on('hide.bs.modal', function(e) {
                        //     $(this).find('.modal-dialog').attr('class', 'modal-dialog animated fadeOut');
                        // });

                        // modal: swipe to close
                        $target.one('swiperight', function(e) {
                            $target.modal('hide');
                        });

                        $target_body.html(data);
                        $target.modal();
                    });
                });

            },

            finalize: function() {

                var $body = $('body'),
                    is_team_page = $('[data-team-member]').length;

                $body.on('infinite-scroll-posts-end', function(e) {
                    $('#infinite-handle button').attr('disabled', true);
                    $body.addClass('infinity-end').removeClass('infinity-success');
                });

                // $body.on('infinite-scroll-posts-more', function(e) {
                //     self.element.append(self.handle);
                //     $body.addClass('infinity-end').removeClass('infinity-success');
                // });

                // triggered after posts are appended
                // $body.on('post-load', function(e) {
                // });

                if (is_team_page) {
                    var team_member = window.location.hash.replace('#', '');

                    if (team_member) {
                        $link = $('[data-team-member="'+team_member+'"]');

                        if ($link.length)
                            $link.click();
                    }
                }

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
